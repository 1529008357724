/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// These styles should be in the global file
.modal-wrapper.sc-ion-modal-md,
.modal-shadow.sc-ion-modal-md {
  background: #fff;
}

.alert-wrapper.sc-ion-alert-md {
  background-color: #fff;
}

.alert-title.sc-ion-alert-md {
  color: #757575;
}

.alert-message.sc-ion-alert-md,
.alert-message.sc-ion-alert-ios {
  white-space: break-spaces;
}

span.wrapped {
  padding-bottom: 5px;
}

.modal-shadow.sc-ion-modal-ios {
  display: none;
}

ion-alert {
  h3.alert-sub-title {
    color: #757575;
    white-space: pre-line;
  }

  h2.alert-title {
    font-size: 20px;
    white-space: pre-line;
  }
}

.register-task-modal {

  @media only screen and (max-height: 992px) and (max-width: 767px) and (orientation: portrait) {
    .register-task-header {
      margin-top: 30px;
    }
  }

  .modal-wrapper {
    --border-radius: 0;

    .textarea-wrapper {
      height: 100%;
      .native-textarea {
        height: 100%;
      }
    }
  }

  // For browser window width of min 992px or bigger
  @media only screen and (min-width: 992px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 50%;
      width: 100%;
      max-width: 60%;
    }
  }

  @media only screen and (min-width: 992px) and (orientation: portrait) {
    .modal-wrapper {
      height: 337px;
      max-height: 100vh;
      min-height: 320px;
      width: 100%;
      max-width: 70%;
    }
  }

  // For browser window width between 768px - 991px
  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 80%;
      width: 100%;
      max-width: 70%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
    .modal-wrapper {
      max-height: 60%;
      width: 100%;
      max-width: 70%;
    }
  }

  // For browser window width of max 767px or smaller
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 85%;
      width: 100%;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 400px;
      max-height: 60%;
      max-width: 85%;
    }
  }

}

.register-task-modal-with-calendar {

  // For browser window width of min 992px or bigger
  @media only screen and (min-width: 992px) and (orientation: landscape) {
    .modal-wrapper {
      min-height: 660px;
    }
  }

  @media only screen and (min-width: 992px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 700px;
    }
  }

  // For browser window width between 768px - 991px
  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
    .modal-wrapper {
      min-height: 700px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 700px;
    }
  }

  // For browser window width of max 767px or smaller
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 95%;
      max-width: 95%;
    }
  }

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 690px;
    }
  }

  .calendar {
    .days-box {
      .days {
        height: 34px;
        margin-bottom: 4px;
        button.days-btn {
          border-radius: 34px;
          width: 34px;
          height: 34px;
        }
      }
    }
  }

}

.mark-task-done-modal {

  .modal-wrapper {
    --border-radius: 0;

    .textarea-wrapper {
      height: 100%;
      .native-textarea {
        height: 100%;
      }
    }
  }

  // For browser window width of min 992px or bigger
  @media only screen and (min-width: 992px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 50%;
      width: 100%;
      max-width: 60%;
    }
  }

  @media only screen and (min-width: 992px) and (orientation: portrait) {
    .modal-wrapper {
      height: 237px;
      max-height: 100vh;
      min-height: 220px;
      width: 100%;
      max-width: 70%;
    }
  }

  // For browser window width between 768px - 991px
  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 80%;
      width: 100%;
      max-width: 70%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
    .modal-wrapper {
      max-height: 60%;
      width: 100%;
      max-width: 70%;
    }
  }

  // For browser window width of max 767px or smaller
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 85%;
      width: 100%;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 300px;
      max-height: 60%;
      max-width: 85%;
    }
  }

}

.mark-task-done-modal-with-calendar {

  // For browser window width of min 992px or bigger
  @media only screen and (min-width: 992px) and (orientation: landscape) {
    .modal-wrapper {
      min-height: 560px;
    }
  }

  @media only screen and (min-width: 992px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 600px;
    }
  }

  // For browser window width between 768px - 991px
  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
    .modal-wrapper {
      min-height: 600px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 600px;
    }
  }

  // For browser window width of max 767px or smaller
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .modal-wrapper {
      max-height: 90%;
      max-width: 90%;
    }
  }

  @media only screen and (max-width: 767px) and (orientation: portrait) {
    .modal-wrapper {
      min-height: 590px;
    }
  }

  .calendar {
    .days-box {
      .days {
        height: 34px;
        margin-bottom: 4px;
        button.days-btn {
          border-radius: 34px;
          width: 34px;
          height: 34px;
        }
      }
    }
  }

}

.select-popover {
  --min-width: 320px;

  .popover-content.sc-ion-popover-md {
    border-radius: 2px;
  }

  .list-md {
    padding-top: 0;
    padding-bottom: 0;
  }
}

ion-popover.open-chat-modal::part(content) {
  top: 110px;
  left: unset;
  right: 50px;
  width: 320px;
}

ion-popover.task-type-instructions {
  --width: 350px;

  ion-content {
    --padding-bottom: 20px;
  }

  p.task-type-instructions {
    padding-left: 18px;
    padding-right: 16px;
  }

  a.task-type-url {
    padding-left: 18px;
    padding-bottom: 16px;
  }
}

.popover-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  ion-button {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: normal;
    --padding-start: 2px;
    --padding-end: 2px;
    --color: #fff;
  }
  ion-icon {
    font-size: 1.8rem;
    padding-left: 5px;
  }
}

.popover-container-inline {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  ion-button {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: normal;
    --padding-start: 2px;
    --padding-end: 2px;
    --color: #000;
    --background-activated: #fff;
    --background: #fff !important;
    --background-hover: #fff !important;
  }
  ion-icon {
    font-size: 1.8rem;
    padding-left: 5px;
  }
}

@media only screen and (max-width: 370px) {
  .open-video-call-modal {
    .popover-content {
      right: 10px !important;
    }
  }
}

ion-searchbar {
    button{
      font-size: 1.8rem !important;
      top: -2px;
      position: relative;
    }
}

ion-calendar-modal,
ion-calendar {
  margin-bottom: 15px;
  padding: 0 !important;
  border: 0.55px solid #999;

  &,
  p,
  div,
  ion-button {
    font-size: .9rem !important;

    --font-size: .9rem !important;
  }

  h4,
  ion-title {
    font-size: 1.1rem !important;

    --font-size: 1.1rem !important;
  }

  .days-btn.today {
    background: var(--ion-color-chat-textareat-background) !important;
  }
}

ion-calendar-modal {
  margin-bottom: 0;
}

//ion-toast::part(button) {
//    color: black !important;
//    background: green;
//    border-radius: 14px;
//}

//.incoming-call-toast {
//  left: calc(50% - 30%);
//  max-width: 60%;
//}